body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}
:root {
  --primary: #f75b28;
}

 /* .active {
  width: auto;

  background: linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.25);
  color: white;

  padding: 10px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
 
 
}  */